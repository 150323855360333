/**
 *    SPDX-License-Identifier: Apache-2.0
 */
/* Dark-Theme is switched by the class name */

.dark-theme svg.custom .node circle {
	fill: #f3f3ff;
	stroke: #2593b8;
}

.dark-theme svg.custom .node text {
	background-color: #444;
	fill: #000000;
}

/*Tables Styling*/
.dark-theme .table-hover tbody tr:hover td,
.dark-theme .table-hover tbody tr:hover th {
	transition: background-color 1s ease;
	background-color: #5e558e !important;
	opacity: 0.8;
}

.dark-theme .-pageJump input {
	background: #7165ad !important;
	color: #ffffff !important;
}
.dark-theme .select-wrap select {
	background: #7165ad !important;
	color: #ffffff !important;
}

.dark-theme li .active {
	color: #ffffff !important;
	background-color: #5e558e !important;
}
.dark-theme li .active:hover {
	color: #ffffff !important;
}

.dark-theme .active .nav-link {
	color: #ffffff;
	fill: #ffffff;
}

/*Time Chart and Chart stats */
.dark-theme .nav-link:hover {
	color: #007bff !important;
}
.dark-theme .rt-noData {
	color: #ffffff !important;
	background: transparent !important;
}
